// src/App.js

import { useEffect, useState, useRef } from 'react';
import { BrowserProvider, parseEther, formatEther, Contract } from 'ethers';

const MCSASHA_ADDRESS = "0x6DFE63380149E04f4DD9BD7E8d892eEc28878556";
const STAKING_ADDRESS = "0xF65a5AEf0076359dfEFaA3ab795eeABa85E783F5";

import MCSASHA_ABI from './MCSASHA_ABI.json';
import STAKING_ABI from './STAKING_ABI.json';
import './App.css';

function App() {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [account, setAccount] = useState(null);

  const [stakeAmount, setStakeAmount] = useState("");
  const [unstakeAmount, setUnstakeAmount] = useState("");
  const [balance, setBalance] = useState("0");
  const [staked, setStaked] = useState("0");
  const [pendingRewards, setPendingRewards] = useState("0");
  const [allowance, setAllowance] = useState(0n);

  const [totalStaked, setTotalStaked] = useState("0");

  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  // Constants
  const TOTAL_MCSASHA = 1000000000n * (10n ** 18n);
  const MAX_UINT256 = 2n ** 256n - 1n;
  const TOTAL_MCBURGER = 1000000; // 1,000,000 total MCBURGER

  useEffect(() => {
    if (window.ethereum) {
      const _provider = new BrowserProvider(window.ethereum);
      setProvider(_provider);
    }
  }, []);

  const connectWallet = async () => {
    if (!provider) return;
    try {
      const accounts = await provider.send("eth_requestAccounts", []);
      setAccount(accounts[0]);
      const _signer = await provider.getSigner();
      setSigner(_signer);
      await fetchAllData(accounts[0], _signer);
    } catch (error) {
      console.error("Failed to connect wallet:", error);
    }
  };

  const fetchAllData = async (userAddress, _signer) => {
    await fetchBalances(userAddress, _signer);
    await calculatePendingRewards(userAddress, _signer);
    await fetchTotalStaked(_signer);
  };

  const fetchBalances = async (userAddress, _signer) => {
    if (!_signer) return;
    const mcSasha = new Contract(MCSASHA_ADDRESS, MCSASHA_ABI, _signer);
    const staking = new Contract(STAKING_ADDRESS, STAKING_ABI, _signer);

    const bal = await mcSasha.balanceOf(userAddress);
    setBalance(formatEther(bal));

    const stakerInfo = await staking.stakers(userAddress);
    setStaked(formatEther(stakerInfo.amount));

    const currentAllowance = await mcSasha.allowance(userAddress, STAKING_ADDRESS);
    setAllowance(BigInt(currentAllowance.toString()));
  };

  const calculatePendingRewards = async (userAddress, _signer) => {
    if (!_signer) return;
    const staking = new Contract(STAKING_ADDRESS, STAKING_ABI, _signer);

    const stakerInfo = await staking.stakers(userAddress);
    const amount = BigInt(stakerInfo.amount.toString());
    const rewardBlock = BigInt(stakerInfo.rewardBlock.toString());

    const totalStakedValue = BigInt((await staking.totalStaked()).toString());
    const currentBlock = BigInt(await provider.getBlockNumber());
    const blocksPassed = currentBlock > rewardBlock ? currentBlock - rewardBlock : 0n;

    if (amount === 0n || totalStakedValue === 0n || blocksPassed === 0n) {
      setPendingRewards("0");
      return;
    }

    const userReward = (amount * blocksPassed * 1000000000000000000n) / TOTAL_MCSASHA;
    setPendingRewards(formatEther(userReward.toString()));
  };

  const fetchTotalStaked = async (_signer) => {
    if (!_signer) return;
    const staking = new Contract(STAKING_ADDRESS, STAKING_ABI, _signer);
    const total = await staking.totalStaked();
    setTotalStaked(formatEther(total));
  };

  const handleApprove = async () => {
    if (!signer || !stakeAmount) return;
    const mcSasha = new Contract(MCSASHA_ADDRESS, MCSASHA_ABI, signer);
    const amountWei = parseEther(stakeAmount);
    let tx = await mcSasha.approve(STAKING_ADDRESS, amountWei);
    await tx.wait();
    alert("Approved successfully!");

    const updatedAllowance = await mcSasha.allowance(account, STAKING_ADDRESS);
    setAllowance(BigInt(updatedAllowance.toString()));
  };

  const handleStake = async () => {
    if (!signer || !stakeAmount) return;
    const staking = new Contract(STAKING_ADDRESS, STAKING_ABI, signer);
    const amountWei = parseEther(stakeAmount);
    let tx = await staking.stake(amountWei);
    await tx.wait();
    alert("Staked successfully!");
    await fetchAllData(account, signer);
  };

  const handleApproveAndStake = async () => {
    if (!signer || !stakeAmount) return;
    const mcSasha = new Contract(MCSASHA_ADDRESS, MCSASHA_ABI, signer);
    const staking = new Contract(STAKING_ADDRESS, STAKING_ABI, signer);

    let tx = await mcSasha.approve(STAKING_ADDRESS, MAX_UINT256);
    await tx.wait();
    alert("Approved unlimited successfully!");

    const updatedAllowance = await mcSasha.allowance(account, STAKING_ADDRESS);
    setAllowance(BigInt(updatedAllowance.toString()));

    const amountWei = parseEther(stakeAmount);
    tx = await staking.stake(amountWei);
    await tx.wait();
    alert("Staked successfully!");

    await fetchAllData(account, signer);
  };

  const handleUnstake = async () => {
    if (!signer || !unstakeAmount) return;
    const staking = new Contract(STAKING_ADDRESS, STAKING_ABI, signer);
    const amountWei = parseEther(unstakeAmount);
    let tx = await staking.unstake(amountWei);
    await tx.wait();
    alert("Unstaked successfully!");
    await fetchAllData(account, signer);
  };

  const handleClaim = async () => {
    if (!signer) return;
    const staking = new Contract(STAKING_ADDRESS, STAKING_ABI, signer);
    let tx = await staking.claimRewards();
    await tx.wait();
    alert("Rewards claimed!");
    await fetchAllData(account, signer);
  };

  const toggleMusic = () => {
    if (!audioRef.current) return;
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const handleMaxStake = () => {
    setStakeAmount(balance);
  };

  const shortenAddress = (addr) => {
    if (!addr) return "";
    return `${addr.slice(0, 6)}...${addr.slice(-4)}`;
  };

  // Formatting and calculations for final display
  const totalSupply = 1000000000;
  const totalStakedNum = parseFloat(totalStaked);
  const stakedPercentage = totalStakedNum > 0 ? (totalStakedNum / totalSupply) * 100 : 0;
  const stakedPercentageStr = stakedPercentage.toFixed(1); 
  const totalStakedRounded = Math.round(totalStakedNum);
  const formattedTotalStaked = new Intl.NumberFormat('en-US').format(totalStakedRounded);

  // Convert strings to numbers
  const balanceNum = parseFloat(balance);
  const stakedNum = parseFloat(staked);
  const pendingNum = parseFloat(pendingRewards);

  // Round all to integers for MCSASHA and show MCBURGERS with two decimals
  // The user wants MCSASHA as full integers and MCBURGERS with .00 format
  const balanceRounded = Math.round(balanceNum);
  const stakedRounded = Math.round(stakedNum);
  
  // For Pending MCBURGERS, we keep two decimals
  // If we want always two decimals: 
  const pendingFormatted = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(pendingNum);

  const balanceFormatted = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(balanceRounded);
  const stakedFormatted = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(stakedRounded);

  // APR calculation:
  // MCBURGER per MCSASHA per year = TOTAL_MCBURGER / totalStakedNum
  // If originally apr was something else, now we do:
  // We want a final APR that was 0.43% to become 43%, etc.
  let aprDisplay = "0% (USD)";
  let aprExplanation = "";

  if (totalStakedNum > 0) {
    const mcburgerPerMcsashaPerYear = TOTAL_MCBURGER / totalStakedNum;
    // Originally say fraction was 0.0043 => 0.43%
    // Now multiply by 100 => 43%
    const aprValueFraction = mcburgerPerMcsashaPerYear * 100; // from fraction to percent
    const finalApr = aprValueFraction * 100; // multiply by 100 again

    aprDisplay = `${finalApr.toFixed(2)}% (USD)`;
    const mcburgerPer100Mcsasha = mcburgerPerMcsashaPerYear * 100;

    aprExplanation = 
      `1 MCBURGER is produced each block (every ~8s) and divided among all stakers.\n` +
      `Currently, from 100 staked MCSASHA you get about ${mcburgerPer100Mcsasha.toFixed(2)} MCBURGERs per year.`;
  } else {
    aprDisplay = `0% (USD)`;
    aprExplanation = "No staking, no rewards.";
  }

  return (
    <div className="container">
      <header className="header">
        <div className="logo-container">
          <img src="/logo512.png" alt="McSasha Logo" className="logo" />
          <h1>McSasha Staking</h1>
        </div>
        <div className="header-controls">
          {!account && (
            <button className="btn primary" onClick={connectWallet}>Connect Wallet</button>
          )}
          {account && <p className="account">Connected: {shortenAddress(account)}</p>}
          {account && (
            <button className="btn music-btn" onClick={toggleMusic}>
              {isPlaying ? "🔊 Music ON" : "🔇 Music OFF"}
            </button>
          )}
        </div>
      </header>
      
      <audio ref={audioRef} src="/music.mp3" loop />

      {account && (
        <main className="main-content">
          <div className="info-box">
            <p>Total Staked: {stakedPercentageStr}% ({formattedTotalStaked} MCSASHA)</p>
            <p>Estimated APR: {aprDisplay}</p>
            <p style={{whiteSpace: 'pre-line'}}>{aprExplanation}</p>
            <p>Your MCSASHA Balance: {balanceFormatted}</p>
            <p>Your Staked MCSASHA: {stakedFormatted}</p>
            <p>Your Pending MCBURGERS: {pendingFormatted}</p>
          </div>

          <div className="action-box">
            <h2>Stake MCSASHA</h2>
            <div className="stake-input-container">
              <input
                type="text"
                placeholder="Amount to stake"
                value={stakeAmount}
                onChange={(e) => setStakeAmount(e.target.value)}
                className="input-field"
              />
              <button className="btn max-btn" onClick={handleMaxStake}>MAX</button>
            </div>
            <div className="action-btns">
              {allowance >= BigInt(parseEther(balance).toString()) ? (
                <button className="btn primary" onClick={handleStake}>Stake</button>
              ) : (
                <button className="btn primary" onClick={handleApproveAndStake}>Approve & Stake</button>
              )}
            </div>
          </div>

          <div className="action-box">
            <h2>Unstake MCSASHA</h2>
            <input
              type="text"
              placeholder="Amount to unstake"
              value={unstakeAmount}
              onChange={(e) => setUnstakeAmount(e.target.value)}
              className="input-field"
            />
            <button className="btn primary" onClick={handleUnstake}>Unstake</button>
          </div>

          <div className="action-box">
            <h2>Claim Rewards</h2>
            <button className="btn primary" onClick={handleClaim}>Claim MCBURGERS</button>
          </div>

          <div className="trading-links">
            <p>McSasha is available for trading at: 
              <a 
                href="https://units.swop.fi/#/swap?outputCurrency=0x6DFE63380149E04f4DD9BD7E8d892eEc28878556" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="btn-link"
              >
                Swap McSasha
              </a>
            </p>

            <p>McBurgers is available for trading at: 
              <a 
                href="https://units.swop.fi/#/swap?outputCurrency=0x487920d6462bb8EdBe0e0E67EeAB04dC9174303" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="btn-link"
              >
                Swap McBurgers
              </a>
            </p>
          </div>
        </main>
      )}
    </div>
  );
}

export default App;